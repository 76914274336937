import { useEffect, useState } from 'react';
import MinusIcon from '../assets/minus-icon.svg?react';
import PlusIcon from '../assets/plus-alternate-icon.svg?react';
import DeleteIcon from '../assets/delete-icon.svg?react';
import { Heading3 } from './shared/Heading';
import { Button } from './shared/Button/Button';
import { GuestsLegend } from './GuestsLegend';
import { useRootStore } from '../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { HotelConfig } from '../apiMethods/getConfig';
import { nanoid } from 'nanoid';
import { Tooltip } from './Tooltip';

type GuestCategoryProps = {
  name: string;
  count: number;
  onChange: (count: number) => void;
  max?: number;
  min?: number;
  showTooltip?: boolean;
  annotation?: string;
};

const OVERBOOKING_WARNING =
  'Делюкс - максимум 3 гостя + ребенок до 3 лет. Семейный люкс - максимум 4 гостя + ребенок до 3 лет';

const GuestCategory = ({
  name,
  count: _count,
  onChange,
  max,
  min,
  annotation,
}: GuestCategoryProps) => {
  const [count, setCount] = useState(_count);

  const increment = () => {
    if (max && count >= max) {
      return;
    }

    const newCount = count + 1;
    setCount(newCount);
    onChange(newCount);
  };

  const decrement = () => {
    if (min && count <= min) {
      return;
    }

    const newCount = count <= 0 ? 0 : count - 1;
    setCount(newCount);
    onChange(newCount);
  };

  return (
    <div className="mb-2 flex items-center justify-between text-neutral-900">
      <div className="flex flex-col">
        <span className="text=[28px] font-normal">{name}</span>
        <span className="text-[10px] font-light leading-3">{annotation}</span>
      </div>

      <span className="flex items-center">
        <Button style="secondary" variant="link" onClick={decrement}>
          <MinusIcon />
        </Button>
        <span className="w-8 text-center text-[16px] font-normal">{count}</span>
        <Button style="secondary" variant="link" onClick={increment}>
          <PlusIcon />
        </Button>
      </span>
    </div>
  );
};

const RoomHeader = ({
  onDelete,
  roomIndex,
  showTooltip,
}: {
  onDelete?: () => void;
  roomIndex: number;
  showTooltip: boolean;
}) => (
  <div className="mb-3 flex justify-between">
    <Tooltip isOpen={showTooltip} anchor={<h6 className="font-medium">Номер {roomIndex + 1}</h6>}>
      <div>{OVERBOOKING_WARNING}</div>
    </Tooltip>

    {onDelete && (
      <div
        className="flex cursor-pointer text-sm text-neutral-70 hover:text-neutral-700"
        onClick={onDelete}
      >
        <span>Удалить номер</span>
        <DeleteIcon className="h-5 w-5" />
      </div>
    )}
  </div>
);

type GuestRoomProps = {
  id: string;
  params: {
    adults: number;
    childs: { id: number; count: number; code: string; use_in_calc: boolean }[];
  };
};

const GuestRoom = observer(
  ({
    room,
    onDelete,
    roomIndex,
    maxAdults,
    childs,
    onChange,
  }: {
    room: GuestRoomProps;
    onDelete?: (id: string) => void;
    roomIndex: number;
    maxAdults: number;
    childs: HotelConfig['hotel_child_categories'];
    onChange: (params: Partial<GuestRoomProps['params']>) => void;
  }) => {
    const onChildrenChange = (childCategoryId: number, count: number) => {
      const newChilds = childs.map(cc => {
        if (cc.id === childCategoryId) {
          return {
            id: cc.id,
            count: count,
            code: cc.code,
            use_in_calc: cc.use_in_culc,
          };
        }

        const existingCount = room.params.childs.find(x => x.id === cc.id)?.count;
        return {
          id: cc.id,
          count: existingCount || 0,
          code: cc.code,
          use_in_calc: cc.use_in_culc,
        };
      });

      onChange({
        childs: newChilds,
      });
    };

    const { paramsStore } = useRootStore();

    const { adults: adultsCount } = room.params;

    const childsCount = room.params.childs
      .filter(c => c.use_in_calc)
      .reduce((count, c) => count + c.count, 0);

    const isCorpMode = paramsStore.rateMode == 'CORP';
    const isOverbooked = adultsCount + childsCount > 3;

    return (
      <article className="my-4 border-b border-neutral-30 pb-5">
        <RoomHeader
          onDelete={onDelete ? () => onDelete?.(room.id) : undefined}
          roomIndex={roomIndex}
          showTooltip={isCorpMode && isOverbooked}
        />

        <GuestCategory
          name="Взрослые"
          count={room.params.adults}
          max={maxAdults}
          min={1}
          onChange={count => onChange({ adults: count })}
        />
        {childs.map(c => (
          <GuestCategory
            key={c.id}
            name={c.name}
            count={room.params.childs.find(child => child.id == c.id)?.count || 0}
            onChange={count => onChildrenChange(c.id, count)}
            max={c.max_childs}
            annotation={`От ${c.from_age} до ${c.to_age} лет`}
          />
        ))}
      </article>
    );
  },
);

const getNewRoom = (
  childs: { id: number; code: string; count: number; use_in_calc: boolean }[],
) => {
  return {
    id: nanoid(),
    params: {
      adults: 2,
      childs,
    },
  };
};

export const Guests = observer(() => {
  const { configStore, paramsStore } = useRootStore();
  const {
    max_rooms: maxRooms,
    max_adults: maxAdults,
    hotel_child_categories: childCategories,
  } = configStore.config || {
    max_rooms: 999,
    max_adults: 999,
  };

  const [guestRooms, setGuestRooms] = useState<GuestRoomProps[]>(paramsStore.guestRooms);

  /** initialize rooms with child categories */
  useEffect(() => {
    if (guestRooms.length || !configStore.childCategories) {
      return;
    }
    setGuestRooms([getNewRoom(configStore.childCategories)]);
  }, [guestRooms, configStore.childCategories]);

  const addGuestRoom = () => {
    if (guestRooms.length >= maxRooms) {
      return;
    }

    setGuestRooms(rooms => [...rooms, getNewRoom(configStore.childCategories || [])]);
  };

  const onDelete = (roomId: string) => {
    if (guestRooms.length == 1) {
      return;
    }
    setGuestRooms(guestRooms.filter(room => room.id != roomId));
  };

  const updateRoomParams = (roomId: string, roomParams: Partial<GuestRoomProps['params']>) => {
    setGuestRooms(rooms =>
      rooms.map(room => {
        if (room.id === roomId) {
          return { ...room, params: { ...room.params, ...roomParams } };
        }
        return room;
      }),
    );
  };

  useEffect(() => {
    paramsStore.setGuestRooms(guestRooms);
  }, [guestRooms, paramsStore]);

  return (
    <>
      <div className="border border-neutral-30 px-5 pb-4 pt-8 xl:flex-1 xl:p-8 xl:pb-6">
        <Heading3 className="text-center">Гости</Heading3>
        {guestRooms.map((room, i) => (
          <GuestRoom
            key={room.id}
            roomIndex={i}
            room={room}
            onDelete={guestRooms.length > 1 ? onDelete : undefined}
            maxAdults={maxAdults}
            childs={childCategories || []}
            onChange={params => updateRoomParams(room.id, params)}
          />
        ))}

        {maxRooms > guestRooms.length && (
          <div className="text-center">
            <Button style="secondary" onClick={addGuestRoom} className="w-full xl:w-auto">
              Добавить номер
            </Button>
          </div>
        )}
      </div>
      <GuestsLegend />
    </>
  );
});

Guests.displayName = 'Guests';
