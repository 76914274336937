import { ReactNode, RefObject, useLayoutEffect, useState } from 'react';
import './sticky-bottom.css';

export const StickyBottom = ({
  children,
  className,
  innerRef,
}: {
  children: ReactNode;
  className?: string;
  innerRef?: RefObject<HTMLElement>;
}) => {
  const [position, setPosition] = useState('sticky');

  useLayoutEffect(() => {
    const root = document.getElementById('root');
    const element = innerRef?.current ?? root;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver(entries => {
      const entry = entries.find(e => e.target === element);
      if (!entry) {
        return;
      }

      const { height } = entry.contentRect;
      const screenHeight = window.innerHeight;

      if (screenHeight > height) {
        position == 'sticky' && setPosition('fixed');
      } else {
        position == 'fixed' && setPosition('sticky');
      }
    });

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [position, innerRef]);

  return (
    <section
      className={
        `${position} custom-shadow bottom-0 w-full bg-neutral-0 p-5 text-center ` + className
      }
    >
      {children}
    </section>
  );
};
