import { MouseEvent, ReactNode, useCallback } from 'react';
import PersonIcon from '../assets/person-icon.svg?react';
import AreaIcon from '../assets/area-icon.svg?react';
import BedIcon from '../assets/bed-icon.svg?react';
import ViewIcon from '../assets/view-icon.svg?react';
import HandicappedIcon from '../assets/handicapped-icon.svg?react';
import './room.css';

import { Link, useNavigate } from 'react-router-dom';
import { PriceAndButtons } from './PriceAndButtons';
import SimpleGallery from '../components/ImageGallery';
import { Button } from '../components/shared/Button/Button';
import { Heading3 } from '../components/shared/Heading';
import noun from 'plural-ru';
import { useRootStore } from '../stores/useRootStore';
import { Skeleton } from '../components/Skeleton';
import { ClampableHtml } from '../components/shared/CampledHtml';
import { observer } from 'mobx-react-lite';

const RoomFeature = ({
  features,
  className,
}: {
  features: { text: ReactNode; icon: ReactNode }[];
  className?: string;
}) => (
  <div className={'flex border-b border-b-neutral-30 py-3 ' + className}>
    {features.map((feature, i) => (
      <span key={i} className="mr-8 flex ">
        {feature.icon}
        <span className="ml-4 ">{feature.text}</span>
      </span>
    ))}
  </div>
);

const FeaturesSkeleton = () => (
  <>
    <div className="mb-6 flex gap-8">
      <Skeleton loading className="h-7 w-1/4 p-3"></Skeleton>
      <Skeleton loading className="h-7 w-1/5 p-3"></Skeleton>
    </div>
    <div className="mb-6 flex gap-8">
      <Skeleton loading className="h-7 w-2/5 p-3"></Skeleton>
      <Skeleton loading className="h-7 w-1/3 p-3"></Skeleton>
    </div>
    <div className="flex gap-8">
      <Skeleton loading className="h-7 w-2/3 p-3"></Skeleton>
    </div>
  </>
);

type RoomProps = {
  id: number;
  name: string;
  images: string[];
  guest_limit: number;
  square: number;
  sleeping_places: number;
  view_type: string | null;
  for_disabled_people: boolean;
  short_description: string;
  min_price: number;
  nights: number;
};

export const Room = observer(({ room, loading }: { room: RoomProps; loading?: boolean }) => {
  const { paramsStore } = useRootStore();
  const {
    id,
    name,
    guest_limit,
    square,
    sleeping_places,
    view_type,
    for_disabled_people,
    short_description,
    min_price,
    nights,
  } = room;

  const navigate = useNavigate();

  const selectRoom = () => {
    paramsStore.selectRoom(room.id);
    navigate('/rates');
  };

  const images = room.images.map(img => ({
    width: 1280,
    height: 864,
    thumbnailURL: img,
    largeURL: img,
  }));

  const isDisabled = paramsStore.hasUnappliedParams;
  const disabledClasses = isDisabled ? 'opacity-40 pointer-events-none' : '';

  const disableClick = useCallback(
    (e: MouseEvent) => {
      if (isDisabled) {
        e.preventDefault();
      }
    },
    [isDisabled],
  );

  return (
    <article className={`mb-6 xl:mb-10 xl:flex xl:flex-row ${disabledClasses}`}>
      <div className="custom-image-container h-[240px] overflow-hidden xl:h-[440px] xl:min-w-[572px]">
        <Skeleton loading={loading} className="h-full ">
          <SimpleGallery galleryID={`room-${id}-gallery`} images={images} />
        </Skeleton>
      </div>
      <div className="flex w-full flex-col p-5 xl:ml-6 xl:p-3">
        <Skeleton loading={loading} className="mb-4 h-[44px] w-2/3">
          <Heading3>{name}</Heading3>
        </Skeleton>
        <div className="mb-4">
          {loading ? (
            <FeaturesSkeleton />
          ) : (
            <>
              <RoomFeature
                features={[
                  { text: `до ${guest_limit} гостей`, icon: <PersonIcon /> },
                  {
                    text: (
                      <>
                        {square} м<sup>2</sup>
                      </>
                    ),
                    icon: <AreaIcon />,
                  },
                ]}
              />
              <RoomFeature
                className="xl:hidden"
                features={[
                  {
                    text: `${sleeping_places} ${noun(sleeping_places, 'спальное место', 'спальных места', 'спальных мест')} `,
                    icon: <BedIcon />,
                  },
                ]}
              />
              <RoomFeature
                className="xl:hidden"
                features={[{ text: 'Вид на сад', icon: <ViewIcon /> }]}
              />
              <RoomFeature
                className="hidden xl:flex"
                features={[
                  { text: '2 спальных места', icon: <BedIcon /> },
                  { text: view_type || '-', icon: <ViewIcon /> },
                ]}
              />
              {for_disabled_people && (
                <RoomFeature
                  features={[
                    { text: 'Для людей с ограниченными возможностями', icon: <HandicappedIcon /> },
                  ]}
                />
              )}
            </>
          )}
        </div>

        <Skeleton loading={loading} className="h-[112px] ">
          <div className="flex-1 xl:leading-7">
            <ClampableHtml html={short_description} maxLine={4} />
          </div>
        </Skeleton>

        <PriceAndButtons price={min_price} isCompact nights={nights} loading={loading}>
          <Skeleton loading={loading} className="mb- h-[48px] w-full xl:ml-3 xl:w-1/4">
            <Link to={`/rooms/${id}/details`} preventScrollReset onClick={disableClick}>
              <Button className="w-full px-6 xl:ml-3 xl:w-auto " style="secondary" variant="filled">
                Подробнее
              </Button>
            </Link>
          </Skeleton>

          <Skeleton loading={loading} className="hidden h-[48px] w-1/4 xl:ml-3 xl:block">
            <Button
              className="hidden w-full px-6 xl:ml-3 xl:block xl:w-auto"
              onClick={e => {
                disableClick(e)
                selectRoom();
              }}
            >
              Забронировать
            </Button>
          </Skeleton>
        </PriceAndButtons>
      </div>
    </article>
  );
});
