import { ButtonHTMLAttributes, ReactNode } from 'react';
import './button.css';

export type ButtonProps = {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: 'accent' | 'primary' | 'secondary';
  variant?: 'filled' | 'outlined' | 'link';
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  role?: ButtonHTMLAttributes<HTMLButtonElement>['role'];
  disabled?: boolean;
  pending?: boolean;
};

export const Button = ({
  children,
  onClick,
  style = 'accent',
  variant = 'filled',
  className = '',
  type,
  role,
  disabled,
  pending,
}: ButtonProps) => {
  return (
    <button
      type={type}
      role={role}
      className={`p-3 ${style} ${variant} ${className} ${pending ? 'custom-pending' : ''}`}
      onClick={onClick}
      disabled={disabled || pending}
    >
      {children}
    </button>
  );
};
